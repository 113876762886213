section {
	width:100%;
	//top:0;
	position:relative;
	float:left;
	overflow:hidden;
	height:100%;
	
	&.scrollable {
		//overflow-y:scroll;
		//height:100% !important;
		
	
	}
	
	&.text-section {
		/*
		-webkit-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
		-moz-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
		box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77); 
		*/
		overflow:visible;
		height:auto;
		min-height:100%;
		z-index:4000;
		//z-index:4000;
		
		main {
			margin-top:0 !important;
		}
	}
}

