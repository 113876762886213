#pofo {
	
	@include flexbox;
	
	
	article {
		margin:0 $g-lr-margin;
		width:50%;
		
		h2 {
			text-align:center;
			margin-bottom:$g-tb-margin;
		}
		
		ul {
			li {
				a {
					span {
						letter-spacing:.02em;
					}
					&:hover {
						text-decoration:none;
						span {
							text-decoration:underline;
						}
					}
				}
			}
		}
	}
}
