main {
	width:60vw;
	margin:0 auto;
	position:relative;
	//float:right;
	//position:fixed;
	//top:0;
	
	@include respond-to(mobile-portrait) {
		width:100vw;
	}
	
	&.text {
		width:60vw;
		float:right;
		margin-right:$g-h-padding * 2;
	    padding-top: 2.6vh;
	    top: -.27em;
		
		@include respond-to(mobile-portrait) {
			width:64.5vw;
		}
	}
}

