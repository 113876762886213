footer {
	text-align:center;
	position:absolute;
	bottom:0;
	width:100%;
	
	.resume-link {
		@extend %smallText;
		text-align:center;
		display:inline-block;
		padding-bottom:$g-tb-margin;
		svg {
			display:block;
			margin:0 auto;
			margin-bottom:$g-tb-margin/4;
		}
	}
	
}