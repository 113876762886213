.trans {
	transition-duration: $animation-speed;
	transition-timing-function: $transition-timing-function;
}



@mixin transition($transition) {
    -webkit-transition:$transition;
    -moz-transition:$transition;
    -o-transition:$transition;
    transition:$transition;
	//-webkit-transition-timing-function: easeInOutCubic; /* Safari and Chrome */
	//transition-timing-function: easeInOutCubic;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

//rotate
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

.notransition {
	@include transition(none !important);
}

.fade-in {
	
   -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}