//-webkit-overflow-scrolling: touch; /* lets it scroll lazy */

%scroll-container {
	-webkit-overflow-scrolling: touch;
	 overflow-y: scroll;
}

.ui-page {
	height:100%;
	overflow:hidden;
	width:100%;
	position:relative;
}

#wrapper {
    width: 100%;
    height: 100%;
}

.ui-loader {
	display:none !important;
}