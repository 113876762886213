@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

%bg-image {
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
    width: 100%;
    height: 100%;
	background-color:black;
}

%shadow {
	-webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.6);
	-moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.6);
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.6);
	
	@include respond-to(mobile-landscape) {
		-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6);
		-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6);
		box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6);
	}
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}