header {
	position:fixed;
	top:0;
	left:0;
	z-index:4000;
	
	h1 {
		a {
			@include font-size($logo-font-sizes); 
			@include line-height($logo-line-height); 
			position:relative;
			
			svg {
				position:absolute;
				top:0;
				left:0;
				padding-left:$g-h-padding * 2;
				padding-top:$g-v-padding;
				width:20.8vw;
				
				@include respond-to(mobile-portrait) {
					padding-left:$g-h-padding * 4;
				}
				
			}
		}
	}
	
}