@font-face {
	font-family: 'Univers LT 55';
	src: url('../fonts/UniversLT.eot');
	src: url('../fonts/UniversLT.eot?#iefix') format('embedded-opentype'),
		url('../fonts/UniversLT.woff') format('woff'),
		url('../fonts/UniversLT.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Univers LT 55';
	src: url('../fonts/UniversLT-Black.eot');
	src: url('../fonts/UniversLT-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/UniversLT-Black.woff') format('woff'),
		url('../fonts/UniversLT-Black.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
