body, html {
	-webkit-font-smoothing: antialiased;
	width:100%;
	left:0;
	height:100%;
}

body {
	//overflow:hidden;
}


*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
