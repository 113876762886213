/* FLEX */

@mixin flexbox {
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */
	display: flex;   
}


@mixin justify-content($justify){
	-webkit-justify-content: $justify;
	justify-content: $justify;
}

@mixin flex($flex){
	-webkit-flex:$flex; 
	flex:$flex;
} 

@mixin align-content($align){
	-webkit-align-content: $align;
	align-content: $align;
}

@mixin align-self($align){
	-webkit-align-self: $align;
	align-self: $align;
}

@mixin flex-wrap($wrap){
	-webkit-flex-wrap: $wrap;
	flex-wrap: $wrap;
}

@mixin flex-flow($flow){
	-webkit-flex-flow: $flow;
	flex-flow: $flow;
}

@mixin align-items($align){
	-webkit-align-items: $align;
	align-items: $align;
}

@mixin flex-grow($grow){
	-webkit-flex-grow: $grow;
	flex-grow: $grow;
}


@mixin flex-basis($basis){
	-webkit-flex-basis: $basis;
	flex-basis: $basis;
} 


@mixin flex-direction($direction){
	-webkit-flex-direction: $direction;
	flex-direction: $direction;
}


/* LAYOUT GRID */

.site-wrapper {

}

.main-wrapper {
	width:100%;
	height:100%;
	overflow:hidden;
	-webkit-overflow-scrolling: touch; /* lets it scroll lazy */
	
	@include break('ipad'){
		overflow:visible;
		height:auto;
	}
}
	


@mixin col {
    border:0px solid rgba(0,0,0,0);
    float:left;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    -moz-background-clip:padding-box !important;
    -webkit-background-clip:padding-box !important;
    background-clip:padding-box !important;
}

.col {
   @include col;
}

@mixin span($num, $gutter_pc, $gutter_px, $padding, $max_columns) {
    $one_col: (100% - ($gutter_pc * ($max_columns - 1))) / $max_columns;
    width:($one_col * $num) + ($gutter_pc * ($num - 1));
    border-left-width:$gutter_px;
    padding:$padding;
    margin-left:$gutter_pc;
}

@mixin span_first {
    margin-left:0;
}


/* 1. 11 Column Grid 0px - Infinity
-----------------------------------------------------------------------------

Span 1:    9.09090909091%
Span 2:    18.1818181818%
Span 3:    27.2727272727%
Span 4:    36.3636363636%
Span 5:    45.4545454545%
Span 6:    54.5454545455%
Span 7:    63.6363636364%
Span 8:    72.7272727273%
Span 9:    81.8181818182%
Span 10:    90.9090909091%
Span 11:    100%

----------------------------------------------------------------------------- */


    
    .col {
        margin-left:0%;
        padding:0 0.1%;
    }

    /* --- Gridpak variables ---*/
    $max_columns: 11;
    $padding: 0 0.1%;
    $gutter_px: 0;
    $gutter_pc: 0%;


    
    .row .col:first-child {
        @include span_first;
    }
    
    
    /*
    Add your semantic classnames in alongside their corresponding spans here. e.g.

    .span_3,
    .my_semantic_class_name {
        ...
    }

    */
    .span_1 {
        @include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_2 {
        @include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_3 {
        @include span(3, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_4 {
        @include span(4, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_5 {
        @include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_6 {
        @include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_7 {
        @include span(7, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_8 {
        @include span(8, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_9 {
        @include span(9, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_10 {
        @include span(10, $gutter_pc, $gutter_px, $padding, $max_columns);
    }
    .span_11 {
        @include span_first;
        width:100%;
    }
