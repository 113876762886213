nav {
	position:fixed;
	z-index:4000;
	
	ul {
		li {
			display:inline;
			a {
				@extend %h1;
				padding:$g-v-padding $g-h-padding/2;
				display:inline-block;
				font-weight:bold;
				&.active {
					color:$c-white;
				}
			}
			
			
		}
	}
}

nav.issues {
	//left:37vw;
	//top:0;
	position:relative;
	width:100vw;
	right:$g-h-padding * 2;
	display:none;
	left:0;
	
	&.show {
		display:block;
	}
	
	ul {
		
		min-width:300px;
		line-height:1;
		@include flexbox;
		@include justify-content(space-between);
		@include flex-wrap(wrap);
		margin:0 auto;
		//max-width:400px;
		margin-top:$g-v-padding;
		
		@media only screen and (max-width: 1100px) {
			//left:;
			max-width:380px;
		}
		
		@include respond-to(mobile-portrait) {
			position:absolute;
			right:$g-h-padding * 4;
			display:block;
			max-width:50vw;
			text-align:right;
			width: 50vw !important;
		}
		
		li {
			line-height:1;
			//margin-right:$g-h-padding * 2;
			width:50%;
			
			@include respond-to(mobile-portrait) {
				margin-left:$g-h-padding;
				
			}
			
			&:nth-child(2n) {
				text-align:right;
				
				a {
					
				}
			}
			
			a {
				padding:0;
				line-height:1;
				position:relative;
				top:-.2em;
			}
		}
	}
	
}

nav.main-menu {
	bottom:0;
	z-index:99999;
}

nav.left-menu {
	li {
		&:first-child {
			a {
				padding-left:$g-h-padding * 2;
				
				@include respond-to(mobile-portrait) {
					padding-left:$g-h-padding * 4;
				}
			}
		}
	}
}

nav.right-menu {
	right:0;
	li {
		&:last-child {
			a {
				padding-right:$g-h-padding * 2;
				
				@include respond-to(mobile-portrait) {
					padding-right:$g-h-padding * 4;
				}
			}
		}
	}
}

.gradient-overlay {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#9a9a9a+0,9a9a9a+63&0+0,1+63 */
	background: -moz-linear-gradient(top, rgba(154,154,154,0) 0%, rgba(154,154,154,1) 63%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(154,154,154,0) 0%,rgba(154,154,154,1) 63%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(154,154,154,0) 0%,rgba(154,154,154,1) 63%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009a9a9a', endColorstr='#9a9a9a',GradientType=0 ); /* IE6-9 */
	position:fixed;
	bottom:0;
	width:100%;
	height:5vw;
	z-index:1;
	
	@include respond-to(mobile-portrait) {
		height:10vw;
	}
}