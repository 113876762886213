.issue-information {
	position:absolute;
	//bottom:$g-v-padding;
	width:50vw;
	//left:50%;
	//margin-left:-25vw;
	text-align:center;
	z-index:2;
	left: 50%;
	margin-left: -25vw;
	@include opacity(0);
	will-change: transform;
	
	@include respond-to(mobile-portrait) {
		bottom:14vh;
	}
	
	@media only screen and (max-width: 912px) {
	    bottom:14vh;
		top: auto !important;
	}
	
	@include respond-to(mobile-landscape) {
		bottom:$g-v-padding;
		top: auto !important;
	}
	
	&.show {
		@include opacity(1);
	}
	
	h3 {
		display:block;
	}
	
	.count {
		@extend %h1;
	}
}

.issue {
	.main {
		height:100%;
		@include flexbox;
		@include flex-direction(column);
		@include justify-content(space-between);
		@include align-items(center);
		@include opacity(0);
		
		&.show {
			@include opacity(1);
		}
		
		&.fixed {
			position:fixed;
			width:100%;
			pointer-events:none;
		}
		
		&.mobile {
			position:absolute !important;
			transform:none !important;
		}
		
		/* Portrait */
		@media only screen and (min-device-width: 320px) and (max-device-width: 2000px) {
			//position:absolute !important;
			//transform:none !important;
		}
		
		.issue-info {
			z-index:9;
			text-align:center;
			
			div {
				padding-bottom:$g-v-padding;
				
				@include respond-to(mobile-portrait) {
					padding-bottom:14.5vh;
				}
			}
		}
	}
}

#issue-one { 
	background-color:#84a97e; 
	-webkit-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
	-moz-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
	box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77); 
	
	

}

#section3 {
	background-color:#efcc2b; 
	-webkit-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
	-moz-box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
	box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.77);
}

#section2 {
	background-color:#c12021;
}

#issue-two { background-color:#c12021; }
#issue-three { background-color:#e2d026; }


.issue {
	z-index:6;
}

.issue-dummy-button {
	position:absolute;
	width:50vw;
	height:300px;
	z-index:30001;
}


.issue-container {
	//@include flexbox;
	//@include align-content(center);
	//@include justify-content(center);
	//width:28.571vw;
	margin:0 auto;
	//margin-top:7.25%;
	text-align:center;
	height:100%;
	//position:absolute;
	text-align:center;
	margin-top:$g-v-padding;
	//@include opacity(0);
	@include flexbox;
	@include justify-content(center);
	@include align-content(center);
	@include align-items(center);
	
	@media only screen and (max-width: 912px) {
	    width:80vw;
	}
	
	
	
	&.show {
		@include opacity(1);
	}
	
	.cover-options {
		height:100%;
		margin:0 auto;
		display:table-cell;
	}
	
	img {
		height:100%;
		-webkit-box-shadow: 0px 10px 2px -4px rgba(0,0,0,0.77);
		-moz-box-shadow: 0px 10px 2px -4px rgba(0,0,0,0.77);
		box-shadow: 0px 10px 2px -4px rgba(0,0,0,0.77);
		display:none;
		cursor:pointer;
		margin:0 auto;
		width:auto;
		
		&.cover-active {
			//display:block;
		}
		
		&.cover-hidden {
			display:none;
		}
		
		&.active {
			display:block;
		}
	}
	
	@include respond-to(mobile-portrait) {
		//width:100vw;
		
		img {
			height:50%;
			width:auto;
		}
	}
}

.issue {
	
	main {
		display:table;
		position:fixed;
		
		.issue-container {
			display:table-cell;
			vertical-align:middle;
		}
	}
}