#contact {
	p {
		margin-bottom:2em !important;
		a {
			@extend %globalText;
			text-transform:none;
			
			&:hover {
				color:$c-white;
			}
		}
	}
}