%globalText {
	
	
	font-family:'Times', 'Times New Roman', serif;
	@include font-size($body-font-sizes); 
	@include line-height($body-line-height); 
	letter-spacing:.01em;
	color:$c-black;
	font-weight:normal;
	
	@include respond-to(mobile-portrait) {
		font-size:4.5vw !important;
		line-height:5vw !important;
	}
	//letter-spacing: -.005em !important;
}

%smallText {
	@include font-size($body-small-font-sizes); 
	@include line-height($body-small-line-height); 
}

html {
	-webkit-font-smoothing: antialiased;
}

body {
	@extend %globalText;
	-webkit-font-smoothing:antialiased;
}

h1, h2, h3, h4, h5, h6, select, label, textarea,  time, button {
	@extend %globalText;
	word-spacing:initial;
}

%h1 {
	@include font-size($h1-font-sizes); 
	@include line-height($body-line-height); 
	font-family: "Univers LT 55", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform:uppercase;
	letter-spacing:.01em;
	
	@include respond-to(mobile-portrait) {
		font-size:4vw !important;
		line-height:5vw !important;
	}
}

%h3 {
	font-family:'Times', 'Times New Roman', serif;
}

li {
	list-style:none;
}

a {
	color:$c-black;
	text-decoration:none;
	
	&:hover {
		text-decoration:none;
		color:$c-white;
	}
	
	&:focus, &:active {
		outline:none !important;
	}
}

h1, h2 {
	@extend %h1;
}

h1, h2{
	display:inline;
}

h3 {
	@extend %h3;
}

h3 time {
	@extend %h1;
}

a {
	@extend %h1;
}

p {
	margin-bottom:$g-h-padding !important;
	
	@include respond-to(mobile-portrait) {
		margin-bottom:$g-h-padding * 2 !important;
	}
}

p b {
	@extend %h1;
	font-weight:normal;
}

p:empty { display:none; }

a[href^=tel] {

}

/* FORM STYLING */

button {
	background:none;
	border:none;
}

input {
	color:$c-white;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder  {
   color: $c-white;
}

input:-moz-placeholder {
   color: $c-black;
}

input::-moz-placeholder {
   color: $c-black;
}

input:-ms-input-placeholder {
   color: $c-black;
}


input:focus, input:hover, input.over {
	background:$c-background;
	border-color:$c-background;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

::-moz-selection { /* Code for Firefox */
    color: black;
    background: #fff;
}

::selection {
    color: black;
    background: #fff;
}

.break-mobile {
	@include respond-to(mobile-portrait) {
		display:block;
	}
}

.hide-mobile {
	@include respond-to(mobile-portrait) {
		display:none;
	}
}
