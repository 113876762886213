#stockists, .stockists {
	padding-bottom:25vh;
	
	main {
		@include flexbox;
		@include flex-direction(row);
		@include flex-wrap(wrap);
		@include align-content(flex-start);
		@include justify-content(flex-start);
		
		
		ul {
			display:inline;
			width:50%;
			margin-bottom:1em;
			
			@include respond-to(mobile-portrait) {
				width:100%;
				margin-bottom:0;
			}
			
			li {
				margin-bottom:1em;
				
				ul {
					li {
						margin-bottom:0;
						text-indent:-1em;
						margin-left:1em;
					}
				}
				
				&:first-child {
					h2 { @extend %h1; }
				}
			}
		}
	}
	
	.title {
		display:block;
	}
	
	.distribution-list {
		margin-top:3em;
		display:block;
		width:100%;
	}
	
}