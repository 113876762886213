.fade-in {
	@include transition(1s opacity);
	//@include opacity(1);
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}

.change-bg {
	@include transition(2s background);
}

.no-trans {
	-moz-transition: none !important;
	-webkit-transition: none !important;
	-o-transition: color 0 ease-in !important;
	transition: none !important;
	
	* {
		-moz-transition: none !important;
		-webkit-transition: none !important;
		-o-transition: color 0 ease-in !important;
		transition: none !important;
	}
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}